import { useEffect, useState } from "react"
import { URLS } from "../constants"
import { useNavigate } from "react-router-dom"
import { format, startOfYear } from "date-fns"
import { getESATReport } from "../API/services"
import { getAuthToken, getErrorMessage } from "../utils/util"
import { Box, Button, Grid, Stack } from "@mui/material"
import useOrganizations from "../utils/hooks/getOrgList"
import DateRangePicker from "../components/DateComponent"
import { roundUpTheDecimal } from "../utils/numberFormater"
import { useSuperAdminStore } from "../store/SuperAdminStore"
import { useNotificationStore } from "../store/NotificationStore"
import OptionDropDown from "../components/OptionsList/optionsDropDown"
import { failed } from "../components/Notification/NotificationMessages"
import { PollingHandler } from "../utils/pollingHandler"
import SkillsCard from "../components/Assesment/components/SkillsCard"
import { SimpleCardWithTitleAndValue } from "../components/Assesment/components/SimpleCardWithTitleAndValue"

export const tempData = [
  { title: "Organizations", value: 101, url: "#", bgColor: "#90EE90" },
  {
    title: "Screen Assessment",
    value: 102,
    url: URLS?.assessment?.dashboard,
    bgColor: "#ADD8E6",
  },
  {
    title: "Grow Assessment",
    value: 104,
    url: URLS?.assessment?.grow_assessments,
    bgColor: "#FFB6C1",
  },
  {
    title: "Average Assessment Score",
    value: 103,
    url: URLS?.assessment?.avg_assessment_score,
    bgColor: "#B2DFDB",
  },
  {
    title: "Total Applicants",
    value: 105,
    url: URLS?.assessment?.total_applicants,
    bgColor: "#D3D3D3",
  },
  {
    title: "Total Employees",
    value: 106,
    url: URLS?.assessment?.store_test,
    bgColor: "#D8BFD8",
  },
  {
    title: "Top Soft Skills",
    value: 107,
    url: URLS?.assessment?.store_test,
    bgColor: "#FFD580",
  },
]

export const SuperAdminDashBoard = () => {
  const navigate = useNavigate()
  const authToken = getAuthToken()
  const organizationList = useOrganizations()
  const setOrgList = useSuperAdminStore((state) => state?.setOrgList)
  const setCartInfo = useSuperAdminStore((state) => state?.setCardInfo)
  const setTopSoftSkills = useSuperAdminStore(
    (state) => state?.setTopSoftSkills
  )
  const setNotification = useNotificationStore((state) => state.setNotification)

  const getTodayDate = new Date()
  const today = format(getTodayDate, "yyyy-MM-dd")
  const firstOfTheYear = format(startOfYear(getTodayDate), "yyyy-MM-dd")

  const [endDate, setEndDate] = useState<string>(today)
  const [startDate, setStartDate] = useState<string>(firstOfTheYear)
  const [orgOverView, setOrgOverView] = useState<any>()
  const [selectedOption, setSelectedOption] = useState<string>("")
  const [polling, setPolling] = useState<boolean>(false)

  const selectedOrgID: any = organizationList?.find(
    (item: any) => item?.organizationName == selectedOption
  )?.id

  const orgList = organizationList
    ?.filter((org: any) => org?.registrationCompleted === true)
    ?.map((filteredOrg: any) => ({
      id: filteredOrg?.id,
      organizationName: filteredOrg?.organizationName,
    }))
  setOrgList(orgList)

  const handleSelectOrg = (event: any) => {
    setSelectedOption(event?.target?.value)
  }

  const handleNavigate = (url?: string) => {
    url &&
      navigate(url, {
        state: { orgId: selectedOrgID },
      })
  }

  const handleCardUpdate = (
    data?: any,
    url: string = "#",
    isSkillsCard: boolean = false
  ) => {
    const { title, score, bgColor, isSoftSkillsCard, avgScore = {} } = data
    if (isSkillsCard) {
      setTopSoftSkills(data)
      setCartInfo({
        score: 0,
        bgColor: "",
        title: "Top Soft Skills",
        isSoftSkillsCard: isSkillsCard,
      })
    } else {
      setCartInfo({
        title: title,
        score: score,
        bgColor: bgColor,
        isSoftSkillsCard: isSoftSkillsCard,
        avgScore: {
          grow: avgScore?.grow,
          screen: avgScore?.screen,
        },
      })
    }
    url && handleNavigate(url)
  }

  useEffect(() => {
    const initPolling = () => {
      setPolling(true)
    }

    initPolling()
  }, [authToken])

  const fetchESATReport = async () => {
    try {
      const res: any = await getESATReport(
        startDate,
        endDate,
        selectedOrgID ? selectedOrgID : ""
      )
      if (res?.data) setOrgOverView(res?.data)
    } catch (error: any) {
      if (error.response) {
        setNotification(failed(getErrorMessage(error)))
      }
    }
  }

  useEffect(() => {
    fetchESATReport()
  }, [])

  return (
    <>
      {polling && <PollingHandler />}
  
      <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minHeight: "100vh",
    padding: "20px 0", // Padding on top and bottom only
    backgroundColor: "#f5f5f5",
  }}
>
  {/* Filters */}
  <Stack
    direction="row"
    gap={2}
    alignItems="center"
    justifyContent="center"
    sx={{ mb: 3, width: "100%", px: 2 }}
  >
    <DateRangePicker
      endDate={endDate}
      startDate={startDate}
      setEndDate={setEndDate}
      setStartDate={setStartDate}
    />
    <OptionDropDown
      options={organizationList}
      selectedOption={selectedOption}
      handleOnChange={handleSelectOrg}
      setSelectedOption={setSelectedOption}
      showSearchButton={false}
    />
    <Button
      variant="outlined"
      onClick={fetchESATReport}
      size="medium"
    >
      Search
    </Button>
  </Stack>

  {/* Dashboard Cards */}
  <Grid
    container
    spacing={2}
    sx={{
      width: "100%",
      px: 2, // Add horizontal padding only
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Grid item xs={12} sm={6} md={3}>
      <Box
        onClick={() =>
          handleCardUpdate({
            bgColor: "#90EE90",
            title: "Organizations",
            isSoftSkillsCard: false,
            score: organizationList?.length || 0,
          })
        }
      >
        <SimpleCardWithTitleAndValue
          url={URLS?.org}
          bgColor="#90EE90"
          title="Organizations"
          value={organizationList?.length || 0}
        />
      </Box>
    </Grid>

    <Grid item xs={12} sm={6} md={9}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            onClick={() =>
              handleCardUpdate(
                {
                  bgColor: "#ADD8E6",
                  title: "Screen Assessment",
                  score: orgOverView?.SCREEN_ASSESSMENTS || 11,
                  isSoftSkillsCard: false,
                },
                URLS?.assessment?.dashboard
              )
            }
          >
            <SimpleCardWithTitleAndValue
              bgColor="#ADD8E6"
              title="Screen Assessment"
              value={orgOverView?.SCREEN_ASSESSMENTS || 0}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            onClick={() =>
              handleCardUpdate(
                {
                  bgColor: "#FFB6C1",
                  title: "Grow Assessment",
                  score: orgOverView?.GROW_ASSESSMENTS || 0,
                  isSoftSkillsCard: false,
                },
                `${URLS?.assessment?.grow_assessments}?assessment=ESAT™ Grow`
              )
            }
          >
            <SimpleCardWithTitleAndValue
              bgColor="#FFB6C1"
              title="Grow Assessment"
              value={orgOverView?.GROW_ASSESSMENTS || 0}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            onClick={() =>
              handleCardUpdate(
                {
                  bgColor: "#B2DFDB",
                  title: "Average Assessment Score",
                  score:
                    roundUpTheDecimal(
                      orgOverView?.AVERAGE_ASSESSMENT_SCORE
                    ) || 0,
                  isSoftSkillsCard: false,
                  avgScore: {
                    grow: orgOverView?.AVERAGE_GROW_ASSESSMENT_SCORE || 0,
                    screen:
                      orgOverView?.AVERAGE_SCREEN_ASSESSMENT_SCORE || 0,
                  },
                },
                URLS?.assessment?.avg_assessment_score
              )
            }
          >
            <SimpleCardWithTitleAndValue
              bgColor="#B2DFDB"
              title="Average Assessment Score"
              value={
                roundUpTheDecimal(
                  orgOverView?.AVERAGE_ASSESSMENT_SCORE
                ) || 0
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            onClick={() =>
              handleCardUpdate(
                {
                  bgColor: "#D3D3D3",
                  title: "Total Applicants",
                  score: orgOverView?.TOTAL_APPLICANTS || 0,
                  isSoftSkillsCard: false,
                },
                `${URLS?.assessment?.total_applicants}?assessment=ESAT™ Screen`
              )
            }
          >
            <SimpleCardWithTitleAndValue
              bgColor="#D3D3D3"
              title="Total Applicants"
              value={orgOverView?.TOTAL_APPLICANTS || 0}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            onClick={() =>
              handleCardUpdate(
                {
                  bgColor: "#D8BFD8",
                  title: "Total Employees",
                  score: orgOverView?.TOTAL_EMPLOYEES || 0,
                  isSoftSkillsCard: false,
                },
                `${URLS?.assessment?.total_employees}?assessment=ESAT™ Grow`
              )
            }
          >
            <SimpleCardWithTitleAndValue
              bgColor="#D8BFD8"
              title="Total Employees"
              value={orgOverView?.TOTAL_EMPLOYEES || 0}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            onClick={() =>
              handleCardUpdate(
                {
                  grow: orgOverView?.TOP_SKILL_GROW,
                  screen: orgOverView?.TOP_SKILL_SCREEN,
                },
                URLS?.assessment?.avg_assessment_score,
                true
              )
            }
          >
            <SkillsCard
              bgColor="#FFD580"
              title="Top Soft Skills :"
              growSkill={orgOverView?.TOP_SKILL_GROW}
              screenSkill={orgOverView?.TOP_SKILL_SCREEN}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
</Box>
    </>
  )
  
}
