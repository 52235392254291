import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getListOfOrganizations } from "../../API/services";
import { organizationDTO } from "../../utils/Types/DTO";
import {
  Container,
  CssBaseline,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Chip,
  styled,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import TitleBanner from "../../components/TitleBanner/TitleBanner";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { URLS } from "../../constants";
import { formatDate } from "../../utils/util";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
type props = {
  bgColor: string;
};
const StyleChip = styled(Chip)(({ bgColor }: props) => ({
  backgroundColor: bgColor,
  borderRadius: 3,
}));
const OrgListing = () => {
  const navigate = useNavigate();

  const [orgList, setOrgList] = useState<organizationDTO[]>();
  useEffect(() => {
    const getOrgsList = async () => {
      const response = await getListOfOrganizations();
      if (response.data) {
        setOrgList(response.data);
      }
    };
    getOrgsList();
  }, []);

  return (
    <Container component="main" sx={{ padding: 0 }}>
      <CssBaseline />
      {/* <TableComponent typeOfUser="supervisor" />
       */}

      <TitleBanner
        title={"Organization Listing"}
        rightChild={
          <Stack
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/entity/add`);
            }}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <AccountBalanceIcon style={{ color: "white" }} />
            <Typography
              align="center"
              color={"white"}
              variant="h1"
              fontSize={18}
              sx={{ opacity: 1 }}
            >
              Add Organization
            </Typography>
          </Stack>
        }
      />
      <TableContainer style={{ marginTop: "10px" }} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Access Key</TableCell>
              <TableCell>Registration Completed</TableCell>
              <TableCell>Date Added</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orgList &&
              orgList.map((org) => (
                <TableRow>
                  <TableCell>{org?.organizationName}</TableCell>
                  <TableCell>{org?.accessKey}</TableCell>
                  <TableCell>
                    <StyleChip
                      label={org?.registrationCompleted.toString()}
                      bgColor={
                        org?.registrationCompleted ? "#d1fcd7" : "#f5aca4"
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ minWidth: 110 }}>
                    {org?.createdDateTime
                      ? formatDate(org?.createdDateTime)
                      : "-"}
                  </TableCell>
                  <TableCell align="center" sx={{ gap: 2, display: "flex" }}>
                    <Button
                      sx={{
                        padding: "4px 12px",
                        borderRadius: "8px",
                        fontSize: "0.8rem",
                        textTransform: "none",
                        minWidth: "100px",
                      }}
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        navigate(`/assessment/management/add/${btoa(org.id)}`)
                      }
                    >
                      Assessment Management
                    </Button>
                    <Tooltip title="View Admins">
                      <Button
                        // sx={{ marginRight: 1 }}
                        sx={{
                          padding: "4px 12px",
                          borderRadius: "8px",
                          fontSize: "0.8rem",
                          textTransform: "none",
                          minWidth: "100px",
                        }}
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          navigate(`/admin/list/org/${btoa(org.id)}`)
                        }
                      >
                        View Admins
                      </Button>
                    </Tooltip>
                    <Button
                      sx={{
                        padding: "4px 12px",
                        borderRadius: "8px",
                        fontSize: "0.8rem",
                        textTransform: "none",
                        minWidth: "100px",
                      }}
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        navigate(URLS?.edit_entity, {
                          state: {
                            orgId: org?.id,
                            orgName: org?.organizationName,
                            organizationPhone: org?.organizationPhone,
                            organizationAddress: org?.organizationAddress,
                            organizationAddressZipCode:
                              org?.organizationAddressZipCode,
                            organizationAddressState:
                              org?.organizationAddressState,
                            organizationAddressCountry:
                              org?.organizationAddressCountry,
                            organizationMailingAddress:
                              org?.organizationMailingAddress,
                            organizationMailingAddressZipCode:
                              org?.organizationMailingAddressZipCode,
                            organizationMailingAddressState:
                              org?.organizationMailingAddressState,
                            organizationMailingAddressCountry:
                              org?.organizationMailingAddressCountry,
                              organizationWebsite: org?.organizationWebsite,
                          },
                        });
                      }}
                      startIcon={<EditIcon />}
                    >
                      Edit Organization
                    </Button>

                    <Tooltip title="Add Admin">
                      <Button
                        sx={{
                          padding: "4px 12px",
                          borderRadius: "8px",
                          fontSize: "0.8rem",
                          textTransform: "none",
                          minWidth: "100px",
                        }}
                        variant="outlined"
                        size="small"
                        onClick={() => navigate(`/add/admin/${btoa(org.id)}`)}
                        startIcon={<AddIcon />}
                      >
                        Admin
                      </Button>
                    </Tooltip>
                    <Tooltip title="Add Supervisor">
                      <Button
                        sx={{
                          padding: "4px 12px",
                          borderRadius: "8px",
                          fontSize: "0.8rem",
                          textTransform: "none",
                          minWidth: "100px",
                        }}
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          navigate(`/supervisors/add/${btoa(org.id)}`)
                        }
                        startIcon={<AddIcon />}
                      >
                        Supervisor
                      </Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
export default OrgListing;
