const publicAllowedUrlPaths = [
    '/',
    '/entity/register/',
    '/entity/register/',
    '/login',
    '/forgot-password',
    '/change-password/',
    '/reset-password',
    '/entity/public/register'
  ];
  
  export default publicAllowedUrlPaths;
  