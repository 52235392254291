import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getLoggedInUserDetails } from '../../../API/services';
import { useSubscriptionStore } from '../../../store/SubscriptionStore';
import { getCookieByName, getRoleToken } from '../../../utils/util';
import SubscriptionExpired from '../SubscriptionExpired';
import { UserDetailsDTO } from '../../../utils/Types/DTO';


const SubscriptionWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [userDetails, setUserDetails] = React.useState<UserDetailsDTO>();
  const location = useLocation();
  const { hasActiveSubscription, checkSubscriptionStatus } = useSubscriptionStore();
  const userId = getCookieByName('userId');
  const role = getRoleToken();

  useEffect(() => {
    const validateSubscription = async () => {
      if (role === 'SUPER_USER') {
        // Skip subscription check for SUPER_USER
        return;
      }

      if (userId) {
        const userDetails = await getLoggedInUserDetails(userId);
        console.log('User Details:', userDetails.data); // Debug
        await checkSubscriptionStatus(userDetails.data);
      }
    };

    validateSubscription();
  }, [location.pathname, role, userId]); // Re-check subscription status on path change

  // Skip subscription check entirely for SUPER_USER
  if (role === 'SUPER_USER') {
    return <>{children}</>;
  }

  // Show SubscriptionExpired page only if subscription is invalid
  if (!hasActiveSubscription) {
    return <SubscriptionExpired />;
  }

  // Render protected routes if subscription is valid
  return <>{children}</>;
};

export default SubscriptionWrapper;


