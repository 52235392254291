import {
  Box,
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Container,
  Dialog,
  DialogContent,
} from "@mui/material";
import Link from "@mui/material/Link";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Controller, FieldValues, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { boolean, object, string } from "yup";
import { useNotificationStore } from "../../store/NotificationStore";
import { userRegistration, validateAccessKey } from "../../API/services";
import { success, failed } from "../Notification/NotificationMessages";
import { getErrorMessage } from "../../utils/util";
import AccessKeyDialog from "./AccessKeyDialog";
import logo from "../../assets/noshadow.png";

const Register = () => {
  const setNotification = useNotificationStore((state) => state.setNotification);
  const navigate = useNavigate();
  const { accessCode } = useParams<{ accessCode: string }>();

  const [showAccessKeyModal, setShowAccessKeyModal] = useState(!accessCode);
  const [accessKey, setAccessKey] = useState(accessCode || "");
  const [validationResponse, setValidationResponse] = useState<any>({});
  const [showRegistrationComplete, setShowRegistrationComplete] = useState(false);
  const [hrPermission, setHrPermission] = useState(false);

  const validationSchema = object().shape({
    password: string().min(6, "Password must be at least 6 characters").required("Password is required"),
    state: string().required("City/State is required"),
    province: string().required("Province/Country is required"),
    hrPermission: boolean().optional(),
    organizationMailingAddress: string().required("Organization Mailing Address is required"),
    organizationAddress: string().required("Organization Address is required"),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const methods = useForm(formOptions);
  const { handleSubmit } = methods;

  useEffect(() => {
    if (accessKey) {
      console.log("Validating access key:", accessKey);
      validateKey();
    }
  }, [accessKey]);

  const validateKey = async () => {
    try {
      const response = await validateAccessKey({ accessKey });
      if (response.data) {
        console.log("Access key validation response:", response.data);
        setValidationResponse(response.data);
        if (response.data.registrationCompleted) setShowRegistrationComplete(true);
      }
    } catch (error: any) {
      console.error("Error validating access key:", error);
      setNotification(failed(getErrorMessage(error.response?.data)));
    }
  };

  const onFormSubmit = (data: FieldValues) => {
    if (accessKey) {
      const payload = {
        country: data.province,
        firstName: validationResponse.user?.firstName || "",
        lastName: validationResponse.user?.lastName || "",
        email: validationResponse.user?.email || "",
        group: "GRP",
        fullName: `${validationResponse.user?.firstName || ""} ${validationResponse.user?.lastName || ""}`,
        phone: "",
        province: data.province,
        state: data.state,
        yearOfBirth: "",
        hiringManager: hrPermission,
        password: data.password,
        accessKey,
      };
      console.log("Submitting registration payload:", payload);
      userRegistration(payload)
        .then(() => {
          setNotification(success("Registered Successfully"));
          navigate("/login");
        })
        .catch((error) => {
          console.error("Error during registration:", error);
          setNotification(failed(getErrorMessage(error.response?.data)));
        });
    }
  };

  const handleAccessKeySubmit = (key: string) => {
    console.log("Access key submitted:", key);
    setAccessKey(key);
    setShowAccessKeyModal(false);
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <AccessKeyDialog
          showAccessKeyModal={showAccessKeyModal}
          onClose={() => navigate("/login")}
          onSubmit={handleAccessKeySubmit}
        />
        <Dialog
          open={showRegistrationComplete}
          onClose={() => {
            setShowRegistrationComplete(false);
            navigate("/login");
          }}
        >
          <DialogContent>
            <Typography variant="h6">This Entity has been registered already! Login to continue</Typography>
            <Button variant="contained" color="primary" onClick={() => navigate("/login")}>
              Login
            </Button>
          </DialogContent>
        </Dialog>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="Logo" style={{ height: "40px", width: "200px" }} />
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <FormProvider {...methods}>
            <Box component="form" onSubmit={handleSubmit(onFormSubmit)} sx={{ mt: 3 }}>
              {/* Organization Details */}
              <Typography variant="h6" sx={{ mb: 2 }}>
                Organization Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    label="Organization Name"
                    value={validationResponse.organizationName || ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="organizationAddress"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Organization Address"
                        error={!!methods.formState.errors.organizationAddress}
                        helperText={methods.formState.errors.organizationAddress?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="organizationMailingAddress"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Organization Mailing Address"
                        error={!!methods.formState.errors.organizationMailingAddress}
                        helperText={methods.formState.errors.organizationMailingAddress?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {/* Account Owner */}
              <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                Account Owner
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="First Name"
                    value={validationResponse.user?.firstName || ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Last Name"
                    value={validationResponse.user?.lastName || ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    label="Email"
                    value={validationResponse.user?.email || ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="password"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Password"
                        type="password"
                        error={!!methods.formState.errors.password}
                        helperText={methods.formState.errors.password?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="state"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="City / State"
                        error={!!methods.formState.errors.state}
                        helperText={methods.formState.errors.state?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="province"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Province / Country"
                        error={!!methods.formState.errors.province}
                        helperText={methods.formState.errors.province?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <FormControlLabel
                control={
                  <Checkbox
                    {...methods.register("hrPermission")}
                    checked={hrPermission}
                    onChange={(e) => setHrPermission(e.target.checked)}
                  />
                }
                label="Do you want Hiring Manager permission?"
              />

              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Finish Registration
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Container>
    </>
  );
};

export default Register;
