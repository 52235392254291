import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogContent, Typography, TextField, Button, Box } from "@mui/material";
import isEmpty from "lodash-es/isEmpty";
import { Controller, FieldValues, FormProvider, useForm, useFormState } from "react-hook-form";
import { object, string } from "yup";


interface AccessKeyDialogType {
    showAccessKeyModal: boolean;
    onClose: () => void;
    onSubmit: (accessCode:string) => void;
}

const AccessKeyDialog = ({ showAccessKeyModal, onClose, onSubmit }: AccessKeyDialogType) => {
    const validationSchema = object().shape({
        accessCode: string().min(7, 'AccessKey must be at least 7 characters').required('AccessKey is required'),
    });
  

    const formOptions = {
        resolver: yupResolver(validationSchema)
    }
    const methods = useForm(formOptions);

    const { handleSubmit } = methods;
    
    const formState = useFormState({
        control: methods.control,
    });
    const onFormSubmit=(data: FieldValues) => {
        if (!isEmpty(data.accessCode)) {
            console.log(data.accessCode)
            onSubmit(data.accessCode)
        }
    }
    return (

        <Dialog open={showAccessKeyModal} onClose={() => onClose()}>
            <DialogContent>
                <FormProvider {...methods}>
                    <Box component="form" onSubmit={handleSubmit(onFormSubmit)} sx={{ mt: 1 }}>
                        <Typography variant="h6">Please enter the activation key:</Typography>
                                    <Controller
                                        name="accessCode"
                                        control={methods.control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                margin="normal"
                                                fullWidth
                                                id="accessCode"
                                                type="text"
                                                label="Access Key"
                                                name="accessCode"
                                                required
                                                autoFocus
                                                error={!!formState.errors.accessCode}
                                                helperText={formState.errors.accessCode ? formState.errors.accessCode.message : ''}
                                            />
                                        )}
                                    />
                        <Button
                            variant="contained"
                            color="primary"
                         type="submit">
                            Submit
                        </Button>
                        </Box>
                </FormProvider>
            </DialogContent>
        </Dialog>
    );
};

export default AccessKeyDialog;