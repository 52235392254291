import * as React from 'react';
import CreateIcon from '@mui/icons-material/Create';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import GroupsIcon from '@mui/icons-material/Groups';
import { hrMenuItems, mainListItems, secondaryListItems } from './ListItems';
import { FC, useState } from 'react';
import Footer from './BottomBar/Footer';
import { getAuthToken, getPermissions, getRoleToken, hasPermissions, logoutUser, openInNewTab } from '../../utils/util';
import { Button, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HomeIcon from '@mui/icons-material/Home';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { useNavigate } from 'react-router-dom';
import GlobalLoader from '../../API/useAxiosLoader';
import logo from '../../assets/noshadow.png';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArticleIcon from '@mui/icons-material/Article';
import SchoolIcon from '@mui/icons-material/School';
import SettingsIcon from '@mui/icons-material/Settings';
import SubscriptionExpired from '../Payment/SubscriptionExpired';
import { useSubscriptionStore } from '../../store/SubscriptionStore';
import SubscriptionWrapper from '../Payment/Subscription/SubscriptionWrapper';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const MenuDrawer: FC<{ child: JSX.Element | JSX.Element[] }> = ({ child }) => {
  const { hasActiveSubscription } = useSubscriptionStore.getState();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const getRole = getRoleToken();
  const permissions = (getPermissions() ?? "").split(",") ?? [''];
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
   // Render "Subscription Expired" screen if no active subscription
   if (!hasActiveSubscription) {
    return <SubscriptionExpired />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" sx={{ backgroundColor: '#010c23' }} open={open}>
          {/* <GlobalLoader/> */}
          <Toolbar

            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <img src={logo} alt="Logo" style={{ marginRight: '16px', height: '40px', width: '140px' }} />
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            <IconButton color="inherit">
              {/* <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge> */}
            </IconButton>
            {getRoleToken() === "ADMINISTRATOR" && (
              <Button onClick={() => navigate('/screen-buy')} variant='outlined' sx={{
                bgcolor: '#89CFF0', color: '#000', '&:hover': {
                  backgroundColor: '#89CFF0',
                }
              }}>
                Buy Plan
              </Button>
            )}
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
            >
              {
                getRoleToken() === 'SUPER_USER' && (<MenuItem onClick={() => openInNewTab('https://esat-portal.satrde.com:4001/status/live')}>
                  Status
                </MenuItem>)
              }
              <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
              <MenuItem onClick={() => navigate("/update-password")}>Update Password</MenuItem>
              <MenuItem onClick={() => logoutUser()}>Logout</MenuItem>
              {/* Add more menu items as needed */}
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {
              getRoleToken() === 'SUPER_USER' && (
                <React.Fragment>
                  <Tooltip title="Home" placement="right">
                    <ListItemButton onClick={() => navigate("/")}>
                      <ListItemIcon>

                        <HomeIcon />

                      </ListItemIcon>
                      <ListItemText primary="Home" />
                    </ListItemButton>
                  </Tooltip>
                  <Tooltip title="Applicants" placement="right">
                    <ListItemButton onClick={() => navigate("/applicants")}>
                      <ListItemIcon>

                        <PersonIcon />

                      </ListItemIcon>
                      <ListItemText primary="Applicants" />
                    </ListItemButton>
                  </Tooltip>
                  <Tooltip title="Supervisors" placement="right">
                    <ListItemButton onClick={() => navigate("/supervisors")}>
                      <ListItemIcon>
                        <PeopleIcon />
                      </ListItemIcon>
                      <ListItemText primary="Supervisors" />
                    </ListItemButton>
                  </Tooltip>
               
                  <Tooltip title="Administrators" placement="right">
                    <ListItemButton onClick={() => navigate("/admin/list")}>
                      <ListItemIcon>
                      <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Administrators" />
                    </ListItemButton>
                  </Tooltip>
                  <Tooltip title="Organizations" placement="right">
                    <ListItemButton onClick={() => navigate("/org")}>
                      <ListItemIcon>
                        <AccountBalanceIcon />
                      </ListItemIcon>
                      <ListItemText primary="Organizations" />
                    </ListItemButton>
                  </Tooltip>

                  {/* <Tooltip title="Admin" placement="right">
                    <ListItemButton onClick={() => navigate("/add/admin")}>
                      <ListItemIcon>
                        <AccountBoxIcon />
                      </ListItemIcon>
                      <ListItemText primary="Add Admin" />
                    </ListItemButton>
                  </Tooltip> */}
                  {/* <ListItemButton onClick={()=>navigate("/analytics")}>
             <ListItemIcon>
               <LeaderboardIcon />
             </ListItemIcon>
             <ListItemText primary="Analytics" />
           </ListItemButton> */}
                </React.Fragment>
              )
            }
            {
              getRoleToken() == 'ADMINISTRATOR' && (
                <React.Fragment>
                  <Tooltip title="Home" placement="right">
                    <ListItemButton onClick={() => navigate("/home")}>
                      <ListItemIcon>

                        <HomeIcon />

                      </ListItemIcon>
                      <ListItemText primary="Home" />
                    </ListItemButton>
                  </Tooltip>
                  {(hasPermissions('hr', permissions)) && (
                    <Tooltip title="Hiring Manager" placement="right">
                      <ListItemButton onClick={() => navigate("/hr/home")}>
                        <ListItemIcon>

                          <GroupsIcon />

                        </ListItemIcon>
                        <ListItemText primary="Hiring Manager" />
                      </ListItemButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Applicants" placement="right">
                    <ListItemButton onClick={() => navigate("/users")}>
                      <ListItemIcon>

                        <PersonIcon />

                      </ListItemIcon>
                      <ListItemText primary="Applicants" />
                    </ListItemButton>
                  </Tooltip>
                  <Tooltip title="Supervisors" placement="right">
                    <ListItemButton onClick={() => navigate("/supervisors")}>
                      <ListItemIcon>
                        <PeopleIcon />
                      </ListItemIcon>
                      <ListItemText primary="Supervisors" />
                    </ListItemButton>
                  </Tooltip>
                  <Tooltip title="Assessment" placement="right">
                    <ListItemButton onClick={() => navigate("/assessment/assign")}>
                      <ListItemIcon>
                        <ArticleIcon />
                      </ListItemIcon>
                      <ListItemText primary="Assessment" />
                    </ListItemButton>
                  </Tooltip>
                  <Tooltip title="Minimum Scores" placement="right">
                    <ListItemButton onClick={() => navigate("/minimum-scores")}>
                      <ListItemIcon>
                        <CreateIcon />
                      </ListItemIcon>
                      <ListItemText primary="Minimum Scores" />
                    </ListItemButton>
                  </Tooltip>
                  <Tooltip title="Admin" placement="right">
                    <ListItemButton onClick={() => navigate("/add/admin")}>
                      <ListItemIcon>
                        <AccountBoxIcon />
                      </ListItemIcon>
                      <ListItemText primary="Add Admin" />
                    </ListItemButton>
                  </Tooltip>
                  {/* <ListItemButton onClick={()=>navigate("/analytics")}>
             <ListItemIcon>
               <LeaderboardIcon />
             </ListItemIcon>
             <ListItemText primary="Analytics" />
           </ListItemButton> */}
                </React.Fragment>
              )
            }
            {
              getRoleToken() == 'SUPERVISOR' && (
                <React.Fragment>
                  <Tooltip title="Home" placement="right">
                    <ListItemButton onClick={() => navigate("/home")}>
                      <ListItemIcon>

                        <HomeIcon />

                      </ListItemIcon>
                      <ListItemText primary="Home" />
                    </ListItemButton>
                  </Tooltip>
                  {(hasPermissions('hr', permissions)) && (
                    <Tooltip title="Hiring Manager" placement="right">
                      <ListItemButton onClick={() => navigate("/hr/home")}>
                        <ListItemIcon>

                          <GroupsIcon />

                        </ListItemIcon>
                        <ListItemText primary="Hiring Manager" />
                      </ListItemButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Applicants" placement="right">
                    <ListItemButton onClick={() => navigate("/users")}>
                      <ListItemIcon>

                        <PersonIcon />

                      </ListItemIcon>
                      <ListItemText primary="Applicants" />
                    </ListItemButton>
                  </Tooltip>
                  <Tooltip title="Supervisors" placement="right">
                    <ListItemButton onClick={() => navigate("/supervisors")}>
                      <ListItemIcon>
                        <PeopleIcon />
                      </ListItemIcon>
                      <ListItemText primary="Supervisors" />
                    </ListItemButton>
                  </Tooltip>
                  <Tooltip title="Assessment" placement="right">
                    <ListItemButton onClick={() => navigate("/assessment/assign")}>
                      <ListItemIcon>
                        <ArticleIcon />
                      </ListItemIcon>
                      <ListItemText primary="Assessment" />
                    </ListItemButton>
                  </Tooltip>
                  {/* <ListItemButton onClick={()=>navigate("/analytics")}>
             <ListItemIcon>
               <LeaderboardIcon />
             </ListItemIcon>
             <ListItemText primary="Analytics" />
           </ListItemButton> */}
                </React.Fragment>
              )
            }

            {
              getRoleToken() == 'CANDIDATE' && (
                <React.Fragment>
                  <Tooltip title="Home" placement="right">
                    <ListItemButton onClick={() => navigate("/home")}>
                      <ListItemIcon>

                        <HomeIcon />

                      </ListItemIcon>
                      <ListItemText primary="Home" />
                    </ListItemButton>
                  </Tooltip>
                  <Tooltip title="Assessment" placement="right">
                    <ListItemButton onClick={() => navigate("/assessment")}>
                      <ListItemIcon>

                        <ArticleIcon />

                      </ListItemIcon>
                      <ListItemText primary="Assessment" />
                    </ListItemButton>
                  </Tooltip>

                  <Tooltip title="Teachables Courses" placement="right">
                    <ListItemButton disabled onClick={() => navigate("/courses")}>
                      <ListItemIcon>

                        <SchoolIcon />

                      </ListItemIcon>
                      <ListItemText primary="Courses" />
                    </ListItemButton>
                  </Tooltip>
                  {/* <ListItemButton onClick={()=>navigate("/analytics")}>
             <ListItemIcon>
               <LeaderboardIcon />
             </ListItemIcon>
             <ListItemText primary="Analytics" />
           </ListItemButton> */}
                </React.Fragment>
              )

            }
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <SubscriptionWrapper>{child}</SubscriptionWrapper>
          </Container>
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
export default MenuDrawer;