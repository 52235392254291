import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Grid } from '@mui/material';
import { UserDetailsDTO } from '../../utils/Types/DTO';


interface UpdateDialogProps {
    open: boolean;
    onClose: () => void;
    user: UserDetailsDTO;
    onUpdate: (updatedUser: UserDetailsDTO) => void;
}

const UpdateDialog: React.FC<UpdateDialogProps> = ({ open, onClose, user, onUpdate }) => {
    const [updatedUser, setUpdatedUser] = useState(user);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUpdatedUser(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = () => {
        onUpdate(updatedUser);
        // onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Update User Details</DialogTitle>
            <DialogContent>

            <Grid marginTop={1} container spacing={2}>
                    <Grid item xs={6}>
                    <TextField label="First Name" name="firstName" value={updatedUser.firstName} onChange={handleChange} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                        <TextField label="Last Name" name="lastName" value={updatedUser.lastName} onChange={handleChange} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                        <TextField label="Manager" name="manager" value={updatedUser.manager} onChange={handleChange} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                        <TextField label="Phone" name="phone" value={updatedUser.phone} onChange={handleChange} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                        <TextField label="Address" name="address" value={updatedUser.address} onChange={handleChange} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                        <TextField label="ZipCode" name="zipCode" value={updatedUser.zipCode} onChange={handleChange} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                        <TextField label="State" name="state" value={updatedUser.state} onChange={handleChange} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                        <TextField label="Country" name="country" value={updatedUser.country} onChange={handleChange} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                        <TextField label="Year Of Birth" name="yearOfBirth" value={updatedUser.yearOfBirth} onChange={handleChange} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                        <TextField label="Email" name="email" value={updatedUser.email} disabled fullWidth />
                        </Grid>
                        </Grid>
                {/* Assuming fullName is not editable as it's derived from firstName and lastName */}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Cancel</Button>
                <Button onClick={handleSubmit} color="primary">Update</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateDialog;
