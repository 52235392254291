import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import TitleBanner from "../TitleBanner/TitleBanner";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FieldValues,
  FormProvider,
  useForm,
  useFormState,
} from "react-hook-form";
import { object, string } from "yup";
import * as Yup from "yup";
import { useState } from "react";
import { generateAccessCode } from "../../utils/util";
import isEmpty from "lodash-es/isEmpty";
import { entityDTO } from "../../utils/Types/DTO";
import { registerEntity } from "../../API/services";
import { useNavigate } from "react-router-dom";
import { useNotificationStore } from "../../store/NotificationStore";
import { success } from "../Notification/NotificationMessages";

const AddEntity = () => {
  const setNotification = useNotificationStore(
    (state) => state.setNotification
  );
  const navigate = useNavigate();
  const [accessCode] = useState(generateAccessCode());

  const validationSchema = object().shape({
    username: string().required("Email is required").email("Email is invalid"),
    confirm_username: Yup.string()
      .required("Confirm Email is required")
      .oneOf([Yup.ref("username"), ""], "Emails must match"),
    organizationName: string().required("Organization Name is required"),
    organizationMailingAddress: string().optional(),
    organizationMailingAddressZipCode: string().optional(),
    organizationMailingAddressState: string().optional(),
    organizationMailingAddressCountry: string().optional(),

    organizationAddress: string().optional(),
    organizationAddressZipCode: string().optional(),
    organizationAddressState: string().optional(),
    organizationAddressCountry: string().optional(),
    
    firstName: string().required("First Name is required"),
    lastName: string().required("Last Name is required"),
    phone: string().required("Phone is required"),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const methods = useForm(formOptions);

  const { handleSubmit } = methods;
  const formState = useFormState({
    control: methods.control,
  });

  const onFormSubmit = (data: FieldValues) => {
    if (
      !isEmpty(data.firstName) &&
      !isEmpty(data.lastName) &&
      !isEmpty(data.organizationName) &&
      !isEmpty(data.phone) &&
      !isEmpty(data.username)
    ) {
      const payload: entityDTO = {
        organizationName: data.organizationName,
        accessCode: accessCode,
        organizationMailingAddress: data.organizationMailingAddress??"",
        organizationMailingAddressZipCode: data.organizationMailingAddressZipCode??"",
        organizationMailingAddressState: data.organizationMailingAddressState??"",
        organizationMailingAddressCountry: data.organizationMailingAddressCountry??"",
        organizationAddress: data.organizationAddress??"",
        organizationAddressZipCode: data.organizationAddressZipCode??"",
        organizationAddressState: data.organizationAddressState??"",
        organizationAddressCountry: data.organizationAddressCountry??"",
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.username,
        phone: data.phone,
      };
      submitData(payload);
    }
  };

  const submitData = (payload: entityDTO) => {
    registerEntity(payload)
      .then(async (response) => {
        if (response.data) {
          console.log(response.data);
          setNotification(success("Organization Added Successfully"));
          navigate("/org");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container component="main">
      <CssBaseline />
      <TitleBanner title={`Add Organization`} />
      <Paper elevation={3} style={{ padding: "20px", margin: "10px" }}>
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onFormSubmit)}
            sx={{ mt: 1 }}
          >
            {/* Organization Details Section */}
            <Typography variant="h6" sx={{ mb: 2 }}>
              Organization Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="organizationName"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      id="organizationName"
                      type="text"
                      label="Organization Name"
                      name="organizationName"
                      autoComplete="organizationName"
                      required
                      autoFocus
                      error={!!formState.errors.organizationName}
                      helperText={
                        formState.errors.organizationName
                          ? formState.errors.organizationName.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
                {/* Organization Details Section */}
              <Typography variant="h6" sx={{ m:2}}>
              Organization Mailing Address Details
              </Typography>
                <Grid item xs={12}>
                  <Controller
                    name="organizationMailingAddress"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="normal"
                        fullWidth
                        id="organizationMailingAddress"
                        type="text"
                        label="Organization Mailing Address"
                        name="organizationMailingAddress"
                        autoComplete="organizationMailingAddress"
                        error={!!formState.errors.organizationMailingAddress}
                        helperText={
                          formState.errors.organizationMailingAddress
                            ? formState.errors.organizationMailingAddress
                                .message
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="organizationMailingAddressZipCode"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="normal"
                        fullWidth
                        id="organizationMailingAddressZipCode"
                        type="text"
                        label="Organization Mailing Address Zip Code"
                        name="organizationMailingAddressZipCode"
                        autoComplete="organizationMailingAddressZipCode"
                        error={!!formState.errors.organizationMailingAddressZipCode}
                        helperText={
                          formState.errors.organizationMailingAddressZipCode
                            ? formState.errors.organizationMailingAddressZipCode
                                .message
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="organizationMailingAddressState"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="normal"
                        fullWidth
                        id="organizationMailingAddressState"
                        type="text"
                        label="Organization Mailing Address State"
                        name="organizationMailingAddress State"
                        autoComplete="organizationMailingAddressState"
                        error={!!formState.errors.organizationMailingAddressState}
                        helperText={
                          formState.errors.organizationMailingAddressState
                            ? formState.errors.organizationMailingAddressState
                                .message
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="organizationMailingAddressCountry"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="normal"
                        fullWidth
                        id="organizationMailingAddressCountry"
                        type="text"
                        label="Organization Mailing Address Country"
                        name="organizationMailingAddressCountry"
                        autoComplete="organizationMailingAddressCountry"
                        error={!!formState.errors.organizationMailingAddressCountry}
                        helperText={
                          formState.errors.organizationMailingAddressCountry
                            ? formState.errors.organizationMailingAddressCountry
                                .message
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>

                 {/* Organization Details Section */}
                    <Typography variant="h6" sx={{ m:2 }}>
                    Organization Address Details
                    </Typography>

                <Grid item xs={12}>
                  <Controller
                    name="organizationAddress"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="normal"
                        fullWidth
                        id="organizationAddress"
                        type="text"
                        label="Organization Address"
                        name="organizationAddress"
                        autoComplete="organizationAddress"
                        error={!!formState.errors.organizationAddress}
                        helperText={
                          formState.errors.organizationAddress
                            ? formState.errors.organizationAddress.message
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="organizationAddressZipCode"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="normal"
                        fullWidth
                        id="organizationAddressZipCode"
                        type="text"
                        label="Organization Address Zip Code"
                        name="organizationAddressZipCode"
                        autoComplete="organizationAddressZipCode"
                        error={!!formState.errors.organizationAddressZipCode}
                        helperText={
                          formState.errors.organizationAddressZipCode
                            ? formState.errors.organizationAddressZipCode.message
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="organizationAddressState"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="normal"
                        fullWidth
                        id="organizationAddressState"
                        type="text"
                        label="Organization Address State"
                        name="organizationAddressState"
                        autoComplete="organizationAddressState"
                        error={!!formState.errors.organizationAddressState}
                        helperText={
                          formState.errors.organizationAddressState
                            ? formState.errors.organizationAddressState.message
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="organizationAddressCountry"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="normal"
                        fullWidth
                        id="organizationAddressCountry"
                        type="text"
                        label="Organization Address Country"
                        name="organizationAddressCountry"
                        autoComplete="organizationAddressCountry"
                        error={!!formState.errors.organizationAddressCountry}
                        helperText={
                          formState.errors.organizationAddressCountry
                            ? formState.errors.organizationAddressCountry.message
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
            </Grid>

            {/* Account Owner Section */}
            <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
              Account Owner
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="firstName"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      id="firstName"
                      type="text"
                      label="First Name"
                      name="firstName"
                      autoComplete="firstName"
                      required
                      error={!!formState.errors.firstName}
                      helperText={
                        formState.errors.firstName
                          ? formState.errors.firstName.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="lastName"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      id="lastName"
                      type="text"
                      label="Last Name"
                      name="lastName"
                      autoComplete="lastName"
                      required
                      error={!!formState.errors.lastName}
                      helperText={
                        formState.errors.lastName
                          ? formState.errors.lastName.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="username"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      id="username"
                      type="email"
                      label="Email Address"
                      name="username"
                      autoComplete="email"
                      required
                      error={!!formState.errors.username}
                      helperText={
                        formState.errors.username
                          ? formState.errors.username.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="confirm_username"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      id="confirm_username"
                      type="email"
                      label="Confirm Email Address"
                      name="confirm_username"
                      autoComplete="email"
                      required
                      error={!!formState.errors.confirm_username}
                      helperText={
                        formState.errors.confirm_username
                          ? formState.errors.confirm_username.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="phone"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      id="phone"
                      type="text"
                      label="Phone"
                      name="phone"
                      autoComplete="phone"
                      required
                      error={!!formState.errors.phone}
                      helperText={
                        formState.errors.phone
                          ? formState.errors.phone.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Access Code"
                  disabled
                  fullWidth
                  name="accessCode"
                  type="text"
                  id="accessCode"
                  value={accessCode}
                />
              </Grid>
            </Grid>

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Add Organization
            </Button>
          </Box>
        </FormProvider>
      </Paper>
    </Container>
  );
};

export default AddEntity;
