import { create } from 'zustand';
import { getLicenseAvailable } from '../API/services';
import { UserDetailsDTO, SubscriptionDTO } from '../utils/Types/DTO';

interface SubscriptionState {
  hasActiveSubscription: boolean;
  setActiveSubscription: (status: boolean) => void;
  checkSubscriptionStatus: (user: UserDetailsDTO) => Promise<void>;
}

export const useSubscriptionStore = create<SubscriptionState>((set) => ({
  hasActiveSubscription: true,

  setActiveSubscription: (status: boolean) => set({ hasActiveSubscription: status }),

  checkSubscriptionStatus: async (user: UserDetailsDTO) => {
    const organizationIds = Object.keys(user.organizationMap);
    let hasSubscription = false;

    for (const orgId of organizationIds) {
      try {
        const response = await getLicenseAvailable(orgId);
        const data = response.data;

        if (data.esatSubscriptions?.some((sub: SubscriptionDTO) => sub.status === 'active')) {
          hasSubscription = true;
          break;
        }
      } catch (error) {
        console.error(`Error checking subscription for org ${orgId}:`, error);
      }
    }

    set({ hasActiveSubscription: hasSubscription || user.organizationOwner === true });
  },
}));
