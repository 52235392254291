import ChangePassword from "../components/ForgotPassword/ChangePassword";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import Login from "../components/Login/Login";
import PublicRegister from "../components/Register/PublicRegister";
import Register from "../components/Register/Register";

export const PublicRoutes=[
    {
      path:"/login",
      element:<Login/>
    },
    {
        path:"/",
        element:<Login/>
      },
    {
      path:"entity/register/:accessCode/",
      element:<Register/>
  }, 
  {
    path: "entity/register/",
    element: <Register />
  },
    {
        path:"/forgot-password",
        element:<ForgotPassword/>
      }, 
    {
        path:"/change-password/:token",
        element:<ChangePassword/>
      },
      {
        path: "entity/public/register",
        element: <PublicRegister />
      },
  ];